.App {
  text-align: center;
  height: 100%;
  background: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  margin: 0;
  padding: 5px;
  font-weight: 500;
}

h3 {
  margin: 10px;
  font-size: min(10vw, 30px);
  padding: 3px;
}

.basicButton {
  border: none;
  background: black;
  color: white;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  border-radius: min(10vw, 10px);
  font-weight: 900;
  width: min(10vw, 30px);
  height: min(10vw, 30px);
  font-size: min(5vw, 15px);
  text-decoration: none;
}